<template>
    <div class="mx-3">
        <h2 class="px-5 py-5">{{ $t('Material Separation Report') }}</h2>
        <v-row class="px-10 py-1">
            <v-col md="3">
                <AutoCompleteField :label="$t('Material Name')" endPoint="/inventories/materials/auto-complete"
                    v-model="filters.material_name" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('folder name')" endPoint="/inventories/folders/auto-complete"
                    v-model="filters.folder" />
            </v-col>
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.from-date')" type="date"
                    v-model="filters.from">
                </v-text-field>
            </v-col>
            <v-col md="3">
                <v-text-field :dark="$store.state.isDarkMode" :label="$t('hr.time.to-date')" type="date"
                    v-model="filters.to">
                </v-text-field>
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('Client Account')" endPoint="/accounting/account/auto-complete"
                    v-model="filters.client_account" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('cost center')" endPoint="/accounting/cost-center/auto-complete"
                    v-model="filters.cost_center" />
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('term')" endPoint="/terms" v-model="filters.term" />
            </v-col>
            <v-col md="3">
                <v-select :dark="$store.state.isDarkMode" :items="itemsInvoece" :label="$t('Invoice Name')" multiple
                    item-text="name" item-value="id" v-model="filters.invoice_name"></v-select>
            </v-col>
            <v-col md="3">
                <v-select :dark="$store.state.isDarkMode" v-model="filters.material_type" :items="itemMaterialType"
                    :label="$t('Material Type')" item-text="name" item-value="id"></v-select>
            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('Beneficiary')" endPoint="/accounting/account/auto-complete"
                    v-model="filters.beneficiary" />

            </v-col>
            <v-col md="3">
                <AutoCompleteField :label="$t('Inventory')" endPoint="/inventories/auto-complete"
                    v-model="filters.inventory_name" />
            </v-col>
            <v-col md="3">
                <v-autocomplete :dark="$store.state.isDarkMode" color="#757575" :label="$t('Group By')" :items="group_by"
                    item-text="name" item-value="value" v-model="filters.group_by"></v-autocomplete>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="green" dark block @click="getAll(1)">{{ $t('search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <v-btn color="red" dark block @click="clearFilter">{{ $t('Delete Search') }}</v-btn>
            </v-col>
            <v-col md="3" class="mb-3 py-0">
                <ExportExcel :end-point="`inventories/materials/${filters.group_by}/export`" :params="filters" />
            </v-col>
        </v-row>
        <v-container id="regular-tables" fluid tag="section">
            <v-simple-table :dark="$store.state.isDarkMode">
                <thead class="table-heading" v-if="!loading">
                    <tr>
                        <th v-for="(name, i) in tableHeaders" :key="i">
                            {{ $t(name) }}
                        </th>
                    </tr>
                </thead>
                <div class="table__spinnerWrapper" v-if="loading">
                    <app-spinner></app-spinner>
                </div>
                <v-fade-transition mode="out-in" :group="true" tag="tbody" v-if="!loading">
                    <template v-for="data in tableItems">
                        <tr :key="data.uid" style=" background: var(--group-by-col)">
                            <th colspan="9" style=" border: 0; font-size: 1rem">
                                <span v-if="data.key"> {{ data.key }} </span>
                            </th>
                        </tr>
                        <tr v-for="(item, idx) in data.value" :key="idx">
                            <td>{{ item.serial }}</td>
                            <td>{{ item.material_name }}</td>
                            <td>{{ item.group_by }}</td>
                            <td>{{ item.parent_name }}</td>
                            <td>{{ item.in_mat }}</td>
                            <td>{{ item.out_mat }}</td>
                            <td>{{ item.in_mat - item.out_mat }}</td>
                        </tr>
                    </template>
                </v-fade-transition>
            </v-simple-table>
            <div class="d-flex justify-end mt-5">
                <v-row>
                    <v-col md="5 ">
                        <v-switch @change="goToAll" v-model="is_all" color="green" :label="$t('Show All')"></v-switch>
                    </v-col>
                    <app-pagination v-if="(!loading & (paginated))" :disable-pagination="!paginated" :totalPages="total"
                        :page="page" @PaginationValue="applyPagination($event)"></app-pagination>
                </v-row>
            </div>
        </v-container>
    </div>
</template>
  
<script>
import axios from 'axios'
import debounce from "../../../../../helpers/debounce";
import i18n from '@/i18n';
import spinner from "../../../../../components/base/spinner.vue";
import pagination from "../../../../../components/base/pagination.vue";
import ExportExcel from "../../../components/core/ExportExcel.vue";
import AutoCompleteField from '../../../components/core/AutoCompleteField';

export default {
    components: {
        appSpinner: spinner,
        appPagination: pagination,
        ExportExcel,
        AutoCompleteField,
    },

    data: () => ({
        tableItems: [],
        is_all: false,
        paginated: true,
        loading: false,
        page: 1,
        total: 1,
        tableHeaders: ["Material Code", "Material Name", "Group By", "Father Folder", "Input Quantity", "Output Quantity", "balance"],
        group_by: [
            {
                name: i18n.t('type'),
                value: "type",
            },
            {
                name: i18n.t('Inventory'),
                value: "inventory",
            },
            {
                name: i18n.t('Invoice Type'),
                value: "invoice_type",
            },
            {
                name: i18n.t('cost center'),
                value: "cost_center",
            },
        ],
        filters: {
            material_name: null,
            folder: null,
            from: null,
            to: null,
            invoice_name: [],
            client_account: null,
            material_type: null,
            inventory_name: null,
            beneficiary: null,
            cost_center: null,
            term: null,
            group_by: "type",
        },
    }),
    methods: {
        goToAll() {
            if (this.is_all) {
                this.paginated = false;
            } else { this.paginated = true; }
            this.getAll();
        },
        clearFilter() {
            this.filters.client_account = undefined;
            this.filters.folder = undefined;
            this.filters.cost_center = undefined;
            this.filters.from = undefined;
            this.filters.to = undefined;
            this.filters.term = undefined;
            this.filters.material_name = undefined;
            this.filters.inventory_name = undefined;
            this.filters.invoice_name = undefined;
            this.filters.material_type = undefined;
            this.filters.beneficiary = undefined;
            this.page = 1;
            this.getAll();
        },
        async getAll() {
            try {
                this.loading = true;
                const filterTemp = {};
                Object.keys(this.filters).forEach((key) => {
                    if (this.filters[key]) filterTemp[key] = this.filters[key];
                });
                const res = await axios.get(`/inventories/materials/${this.filters.group_by}/index`, {
                    params: {
                        size: 10,
                        page: this.page,
                        paginated: this.paginated,
                        ...filterTemp,
                    },
                });
                const arr = Array.from(res.data.data)
                if (Array.isArray(arr)) {
                    console.log(arr);
                    this.tableItems = arr.map((el) => {
                        return {
                            ...el,
                        };
                    });
                }
                console.log(res.data.data);
                this.tableItems.forEach((item) => {
                    item.uid = this.$uuid.v1();
                });
                this.total = res.data.total;
                this.page = res.data.current_page;
            } finally {
                this.loading = false;
            }
        },
        applyPagination(paginationValue) {
            this.page = paginationValue;
            this.getAll();
        },
        async getallType() {
            const res = await axios.get("/inventories/material-types/value/index");
            this.itemMaterialType = res.data.data;
        },
        async getInvoiceTypes() {
      const res = await axios.get("/inventories/invoice-types/index");
      this.itemsInvoece = res.data.data.map((el) => ({
        ...el,
      }));
    },
    },
    created() {
        this.getInvoiceTypes();
        this.getallType();
        this.getAll();
    },
};
</script>
  
<style></style>
  